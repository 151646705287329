import React from 'react';
import { Dots } from '../components/dots';

export const Main = () => {
  return (
    <div>
      <div id="background">
        <Dots />
      </div>
      <div id="backgroundText">
        <span>ZIEL</span>
      </div>
      <div id="content">
        <div className="main">
          <a href="tel:+989140294290">
            <div className="dj-title">
              <p className="amir-text">ARMAN</p>
              <p className="amiri-text">JOKAR</p>
            </div>
          </a>
        </div>
        <div className="footer">
          <span className="link">
            <a
              href="https://www.instagram.com/arman.aj9/"
              className="instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
